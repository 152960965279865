import axios from 'axios'
import { HTTP } from '../helpers/http-common'
import dotenv from "dotenv"
dotenv.config({ path: "../../frontend" });
const API = process.env.VUE_APP_BACKEND_URL
const loginURL = '/users/token'
const versionUrl = '/users/get_version'
const todaysgoalurl = '/users/todaysgoal'
const previousgoalurl = '/users/previousgoal'
const checkperdayminutes = '/check-per-day-minutes'
const projectsurl = '/project/list'
const groupprojectsurl = '/project/group_project'
const projectfilter = '/project/filterlist/'
const projecttaskurl = '/project/taskProjectList'
const projectidurl = '/project/'
const createprojectsurl = '/project/create'
const deleteprojectsurl = '/project/delete/'
const updateprojectsurls = '/project/update/'
const assignprojecttouserurl = '/project/assign-user'
const updateassignedusersalias = '/project/update-project-users-alias'
const getprojectassignurl = '/project/getassignuser/'
const getprojectunassignuser = '/project/getunassignuser/'

const getTaskCategoryUrl = '/tasks/task_categories/'

const getallyourreportsurl = '/report/your/list'
const getallteammatesreportlisturl = '/report/teammates/list'
const searchyourtaskreporturl = '/report/your/filtercolumns/'
const searchteammatestaskreporturl = '/report/teammates/filtercolumns/'
const getprojectuser = '/report/projectuser'
const gettaskreportbyidurl = '/report/list/'
const createprojecttaskreport = '/report/create'
const updateprojecttaskreport = '/report/update'
const updateapprovedreportdetails = '/report/update-approved-hours'
const deleteprojecttaskreporturl = '/report/delete/'
const approvereporturl = '/report/approve'
const getreportedhoursbydate = '/report/get_reported_hours_by_date'
const getteamleaddropdown = '/report/get_all_team_leads'
const snapshotURL = '/report/snapshotv2/'
const productivityReportUrl = '/report/productivity-report'
const exportProductivityReportUrl = '/report/export-productivity-report'
const approvedHoursReportUrl = '/report/approved-hours-report'
const exportApprovedHoursReportUrl = '/report/export-approved-hours-report'

const userallocationreportlisturl = '/report/user-allocation-report/list'
const userallocationreportfilterlisturl = '/report/user-allocation-report/filterlist/'
const userallocationreportpagesurl = '/report/user-allocation-report/allocation-pages'

const createclienturl = '/client/create'
const clientlisturl = '/client/get'
const searchclienturl = '/client/list/'
const clientlistbyidurl = '/client/get/'
const clientupdateurl = '/client/update/'
const deleteclienturl = '/client/delete/'
const getclientunassignusers = '/client/getunassignuser/'
const setassigneduserstoclient = '/client/set-assigned-users'

const createsubclienturl = '/sub_client/create'
const subclientlisturl = '/sub_client/get'
const searchsubclienturl = '/sub_client/list/'
const subclientlistbyidurl = '/sub_client/get/'
const subclientupdateurl = '/sub_client/update/'
const deletesubclienturl = '/sub_client/delete/'

const getmilestonedateurl = '/milestone/Projectdate/'
const milestonesurl = '/milestone/list'
const searchmilestonesurl = '/milestone/filterlist/'
const createmilestonesurl = '/milestone/create'
const getmilestonesidurl = '/milestone/get/'
const deletemilestoneurl = '/milestone/delete/'
const updatemilestoneurl = '/milestone/update/'
const projectmilestoneurl = '/tasks/projectmilestone/'

const createtaskurl = '/tasks/create'
const searchyourtaskurl = '/tasks/your-task-list/'
const searchteammatestaskurl = '/tasks/teammates-task-list/'
const gettaskidurl = '/tasks/'
const updatetaskurl = '/tasks/update/'
const deletetaskurl = '/tasks/delete/'
const assigntasktouserurl = '/tasks/assign-user'
const gettaskunassignuser = '/tasks/getunassignuser/'
const getpreassignedtoprojectusers = '/tasks/getprojectassignuserlist/'
const getdailyhoursofusers = '/tasks/get_user_daily_hours'
const getalltasks = '/tasks/get-tasks-list'
const updatetaskdetails = '/tasks/update-task-details'

const getCurrentUserURL = '/users/me'
const updatePasswordurl = '/users/change-password'
const updateURL = '/users/log'
const getuserbyidurl = '/users/'
const getuserprofileurl = '/users/user_profile/'
const users = '/users/log'
const createUserUrl = '/users/create'
const displayallusersurl = "/users/all_user_list"
const activeuserurl = "/users/active_user_list"
const projectandreportmeusersurl = "/users/project_and_report_me_users"
const getuserinfourl = '/forgot/getinfo/'
const resetpasswordurl = "/forgot/reset-password"
const getallvaliduserslist = '/users/get-all-users-list'



const userlisturl = '/users/list'
const displayalluserpagesurl = "/users/displayalluserpages"
// const displayallmilestonepagesurl = "/milestone/pages"
const displayyoursreportpagesurl = "/report/yours/reportPages"
const displayteammatesreportpages = '/report/teammates/reportPages'
const userDesignationDropdownUrl = "/users/user_designations"
const userTypeDropdownUrl = "/users/user_type"
const deleteduserslisturl = "/users/list-deleted-users"
const makeuseractiveurl = "/users/make-user-active/"
const deletedusersfilterlist = "/users/deleted-users-filterlist/"


const searchuserurl = '/users/filterlist/'
const deleteuserurl = '/users/delete/'
const updateuserurl = "/users/updateuser/"
const roleuserurl = "/users/role/list"
const addroleurl = "/roles/roles_add"
const permissionurl = "/users/permission/list"
const countriesList = "/users/countries"
const statesList = "/users/states/"
const citiesList = "/users/cities/"
const getlistorganizationurl = "/users/organization"
const attendanceReportURL = "/attendanceReport"
const attendancebytoday = "/attendance_by_date"
const getlivetimespent = "/get_live_time_spent"
const monthlyattendanceReportURL = "/monthlyattendanceReport"
const getreportsbydateURL = "/get-monthly-attendance-report"
const getattendancelogbydate = "/Attendance-logs"
const createrequestforupdateattendance = "/create-attendance-update-request"
const getallupdateattendancerequest = "/attendance-update-request-list/"
const updateattendanceurl = "/update-attendance"
const getassetbyidurl = '/assets/'
const createassetsurl = '/assets/create'
// const assetsurl = '/assets/list'
const updateasseturl = '/assets/update/'
const deleteasseturl = '/assets/delete/'
const itemurl = '/assets/items/list'
const createitemurl = '/assets/items/create/'
const assetfilter = 'assets/filterlist'


const assignedgoalslist = '/goals/assigned-goals'
const teammatesgoals = '/goals/team-mates-goals'
const createusergoal = '/goals/create-user-goal'
const getgoalbyid = '/goals/get-goal-by-id/'
const updategoaldetails = '/goals/update-goal-details'
const goalbyuser = '/goals/get_goals_by_user_id/'
const aliasbyclient = '/client/get_alias_by_client/'
const updatealiasbyclient = '/client/update_alias_by_client'


const getuserconfigurationsurl = '/user_configurations/userconfigurationlist'
const updateuserconfigurationurl = '/user_configurations/update/'

const update_credit_month = '/crons/1_credit_leave_every_month/'
const update_leave_debit_month = '/crons/5_month/'
const update_LWP_month = '/crons/credit_debit_lwp_entry'


const checkusername = '/forgot/request-forgot-password-email'
const user_leave_types = '/leaves/user_leave_types/';
const getleavetype = '/leaves/leave_type'
const getleavesurl = '/leaves/list'
const getleavesbyidurl = '/leaves/'
const createleavesurl = '/leaves/create'
const updateleavesurl = '/leaves/update/'
const udpateapprovedleaves = '/leaves/update_approved_leaves'
const deleteleavesurl = '/leaves/delete/'
const approveleavesurl = '/leaves/approve/'
const rejectleavesurl = '/leaves/reject/'
const yourleavelist = '/leaves/your-leave-list'
const teammatesleavelist = '/leaves/team-mates-leave-list'
const createcreditdebitleaveurl = '/leaves/credit_debit_leave_create'
const updatecreditdebitleaveurl = '/leaves/update_credit_debit_entry'
const deletecreditdebitleaveurl = '/leaves/delete_credit_debit_entry/'
const leaveReportURL = '/leaves/leaveReport/'
const displayAllLeavesPagesUrl = '/leaves/leavespages'
const leavesCreditDebitEntriesUrl = "/leaves/credit_debit_leave_entries"
const categoriesDropdownUrl = "/report/reports_categories";
const getallpriorities = '/priority/list'
const getallprojectstatus = '/status/project/list'
const getalltaskstatus = '/status/task/list'
const getstatusbyid = '/status/task/get/'
const getprioritybyid = '/priority/get/'
// const getallquotesurl = '/quotes/list'
const quotesfilterlist = '/quotes/filterlist'
// const getallquotespages = '/quotes/quotespages'
const getquotesbyid = '/quotes/get/'
const createquoteurl = '/quotes/create'
const updatequotesurl = '/quotes/update/'
const getpreassignedurl = '/image/generate_pre_signed_url'
const deletequotesbyidurl = '/quotes/delete/'
const displaytodayquotesurl = '/quotes/today'
const makequoteseenurl = '/quotes/make-quote-seen'

const getallnotificationsurl = '/notification/list'
const createnotificationurl = '/notification/create'
const deletenotificationbyidurl = '/notification/delete/'

const deleteroleurl = "roles/roles_delete/";
const teammatesreporturl = '/report/teammates_report/'
const dailydiscrepancyurl = '/report/daily_discrepancy/'

const skillsurl = "/skill/list"
// const usersskillsurl = "/skill/users_skill_list"
const addskillsurl = "/skill/add/"
// const deleteskill="/skill/delete/"
const getskillbyuser = "/skill/get_skill/"

const kraurl = "/kra/list"
// const userskraurl = "/kra/users_kra_list"
const addkraurl = "/kra/add/"
const getkrabyid = "/kra/get_kra/"
// const updatekraurl="/kra/update/"
// const deletekra="/kra/delete/"
// const getkrabyuser="/kra/list"

const TimeSpentReportURL = "/report/TimeSpentReport"
const userFilters = "/redmine/redmine_filter";

// Rewards Modue
const getRewardsCategory = '/rewards/list_reward_categories';
const addRewards = "/rewards/create_reward_category";
const updateRewards = "/rewards/update_reward_category";
const deleteRewards = "/rewards/delete_reward_category/";
const getUserRewards = "/rewards/get_users_rewards";
const getRewardsCreditDebitEntries="/rewards/get_rewards_credit_debit_entries";
const setUserAward = "/rewards/award_rewards_to_users";
const updateUserAward = "/rewards/update_user_rewards";
const deleteUserRewards = "/rewards/delete_user_rewards/"
const getRewardSummaryUrl = "/rewards/reward_summary"
const getRewardsRankBoardUrl = "/rewards/get-rewards-rank-board"

const gethierarchyOfUserurl = '/hierarchy/get_user_hierarchy_tree'

// To get milestone according to project in task module
const get_project_milestone_url = '/tasks/get_project_milestones'

//siteusage module
const get_site_module_report = "/site_module_logs/count_by_site/";
const get_site_mobile_module_report = "/mobile_module/get_mobile_site_usage_statistics/";

// Vendors module
let get_vendor_url = "/vendors/filterlist";
let list_vendor_url = "/vendors/list_vendors";
let add_vendor_url = "/vendors/add_vendor";
let update_vendor_url = "/vendors/update_vendor";
let delete_vendor_url = "/vendors/delete_vendor/";
let get_vendor_by_id_url = "/vendors/read_vendor/";

// Asset category modue
let get_category_url = "/asset/category_filterlist";
let get_category_list_url = "/asset/list_asset_categories";
let add_category_url = "/asset/add_asset_category";
let update_category_url = "/asset/update_asset_category";
let delete_category_url = "/asset/delete_asset_category/";
let get_category_by_id_url = "/asset/read_assets_category/";

// Invoice Module
let get_invoice_url = "/invoice/filterlist";
let get_invoice_by_id_url = "/invoice/read_invoice/";
let add_invoice_url = "/invoice/create_invoice";
let update_invoice_url = "/invoice/update_invoice";
let delete_invoice_url = "/invoice/delete_invoice/";

//Assets Module
let get_asset_url = '/asset/filterlist';
let get_asset_history_url = '/asset/users-assets-history';
let add_asset_url = '/asset/add_asset';
let update_inovice_asset_url = '/asset/update_invoice_asset';
let update_asset_url = '/asset/update_asset';
let update_user_asset_url = '/asset/update_assign_asset';
let get_asset_by_id_url = '/asset/read_asset/';
let delete_asset_url = '/asset/delete_asset/';

let assign_asset_url = '/asset/assign_asset_to_user';
let get_user_asset_url = '/asset/get_user_assets/';
let deallocate_asset_url = '/asset/deallocate_asset';
let user_asset_list_url = '/asset/asset_list';

// Profile Project
let user_project_url = "/users/user_projects/";

// Template
let task_template_filterlist = "task_template/filterlist";
let task_template_list = "task_template/list";
let task_template_by_id = "task_template/get_task_template/"
let task_template_add = "task_template/create";
let task_template_upadte = "task_template/update/";
let task_template_delete = "task_template/delete/";


// Summary Report
const add_summary_report = "summary_report/create";
const update_summary_report = "summary_report/update/";
const delete_summary_report = "summary_report/delete/";
const your_summary_report_list = "summary_report/your_filterlist";
const team_summary_report_list = "summary_report/team_filterlist";

//get dashboard summary
const get_dashboard_summary = '/users/get_dashboard_summary'
const createSiteUsageUrl = "/site_module_logs/create";

//mobile create
const createMobileSiteUsageUrl = "/mobile_module/create_mobile_module_log";

export default {
  // Summary Report
  add_summary_report(reqObj){
    return HTTP.post(add_summary_report,reqObj);
  },
  update_summary_report(id,reqObj){
    return HTTP.put(update_summary_report+id,reqObj);
  },
  delete_summary_report(id){
    return HTTP.post(delete_summary_report+id);
  },
  get_your_summary_reports(reqObj){
    return HTTP.post(your_summary_report_list,reqObj);
  },
  get_team_summary_reports(reqObj){
    return HTTP.post(team_summary_report_list,reqObj);
  },
  // Template
  get_task_template(dataObj) {
    return HTTP.post(task_template_filterlist, dataObj);
  },
  get_task_template_by_id(id) {
    return HTTP.get(task_template_by_id + id);
  },
  get_task_template_list() {
    return HTTP.get(task_template_list);
  },
  create_task_template(dataObj) {
    return HTTP.post(task_template_add, dataObj)
  },
  update_task_template(id, dataObj) {
    return HTTP.put(task_template_upadte + id, dataObj);
  },
  delete_task_template(id) {
    return HTTP.delete(task_template_delete + id);
  },
  get_leave_credit_debit_entries(dataObj) {
    return HTTP.post(leavesCreditDebitEntriesUrl, dataObj)
  },
  update_credit_debit_entries(data) {
    return HTTP.put(updatecreditdebitleaveurl, data, null)
  },
  delete_credit_debit_entries(id) {
    return HTTP.delete(deletecreditdebitleaveurl + id)
  },
  // Function for profile projects
  get_user_projects(id) {
    return HTTP.get(user_project_url + id);
  },
  // Function for user assets
  get_user_assets(id) {
    return HTTP.get(get_user_asset_url + id);
  },
  get_assign_asset() {
    return HTTP.get(user_asset_list_url)
  },
  assign_asset_to_user(reqObj) {
    return HTTP.post(assign_asset_url, reqObj, null);
  },
  deallocated_asset_of_user(reqObj) {
    return HTTP.put(deallocate_asset_url, reqObj, null);
  },
  // Function for assets
  addAssets(reqObj) {
    return HTTP.post(add_asset_url, reqObj, null);
  },
  updateInvoiceAssets(reqObj) {
    return HTTP.put(update_inovice_asset_url, reqObj, null);
  },
  updateAssets(reqObj) {
    return HTTP.put(update_asset_url, reqObj, null);
  },
  getAssets(serverParams) {
    return HTTP.post(get_asset_url, serverParams, null);
  },
  getAssetsHistory(serverParams) {
    return HTTP.post(get_asset_history_url, serverParams, null);
  },
  getAssetsById(id) {
    return HTTP.get(get_asset_by_id_url + id);
  },
  deleteAsset(id) {
    return HTTP.delete(delete_asset_url + id);
  },
  // Functions for invoice module
  getInvoices(serverParams) {
    return HTTP.post(get_invoice_url, serverParams, null);
  },
  addInvoice(reqObj) {
    return HTTP.post(add_invoice_url, reqObj, null);
  },
  updateInvoice(reqObj) {
    return HTTP.put(update_invoice_url, reqObj, null);
  },
  getInvoiceById(id) {
    return HTTP.get(get_invoice_by_id_url + id);
  },
  deleteInvoice(id) {
    return HTTP.delete(delete_invoice_url + id);
  },
  // Functions for assets module
  getAssetCategory(dataObj) {
    return HTTP.post(get_category_url, dataObj)
  },
  getAssetCategoryList() {
    return HTTP.get(get_category_list_url)
  },
  getAssetCategoryById(id) {
    return HTTP.get(get_category_by_id_url + id)

  },
  addAssetCategory(reqObj) {
    return HTTP.post(add_category_url, reqObj, null)
  },
  updateAssetCategory(reqObj) {
    return HTTP.put(update_category_url, reqObj, null)
  },
  deleteAssetCategory(id) {
    return HTTP.delete(delete_category_url + id)
  },
  // Functions for vendor module
  getVendors(serverParams) {
    return HTTP.post(get_vendor_url, serverParams, null);
  },
  listVendors() {
    return HTTP.get(list_vendor_url);
  },
  getVendorById(id) {
    return HTTP.get(get_vendor_by_id_url + id);
  },
  addVendor(reqObj) {
    return HTTP.post(add_vendor_url, reqObj, null);
  },
  updateVendor(reqObj) {
    return HTTP.put(update_vendor_url, reqObj, null);
  },
  deleteVendor(id) {
    return HTTP.delete(delete_vendor_url + id);
  },

  get_project_milestone(dataObj) {
    return HTTP.post(get_project_milestone_url, dataObj, null);
  },
  updateUserRewards(dataObj) {
    return HTTP.put(updateUserAward, dataObj, null);
  },
  // To award reward to users
  setUserRewards(dataObj) {
    return HTTP.post(setUserAward, dataObj, null);
  },
  // To get user list
  getUserRewards(dataObj) {
    return HTTP.post(getUserRewards, dataObj, null);
  },

  //For siteusage Module
  getSiteModuleLogReport(params) {
    return HTTP.post(get_site_module_report, params );
  },
  getMobileSiteModuleLogReport(params) {
    return HTTP.post(get_site_mobile_module_report, params );
  },
  createSiteUsage(request) {
    console.log("1", process.env.VUE_APP_SITE_MODULE_LOG); 
    if (process.env.VUE_APP_SITE_MODULE_LOG.toLowerCase() === "true") {
      return HTTP.post(createSiteUsageUrl, {
        site_name: request.site_name,
      }).then((res) => {
        // console.log("API response: Success");
      })
      .catch((err) => {
        console.error("API error:", err.response.data);
      });
    } else {
      console.log("API response: error");
    }
  },

  //mobile usage
  createMobileSiteUsage(request) {
    console.log("1", process.env.VUE_APP_SITE_MODULE_LOG); 
    if (process.env.VUE_APP_SITE_MODULE_LOG.toLowerCase() === "true") {
      return HTTP.post(createMobileSiteUsageUrl, {
        site_name: request.site_name,
        os: request.os,
        version: request.version
      }).then((res) => {
        // console.log("API response: Success");
      })
      .catch((err) => {
        console.error("API error:", err.response.data);
      });
    } else {
      console.log("API response: error");
    }

  },
  
  // For Reward Module
  getRewardsCategories() {
    return HTTP.get(getRewardsCategory);
  },
  getRewardsCreditDebitEntries(reqObj){
    return HTTP.post(getRewardsCreditDebitEntries,reqObj);
  },
  getRewardsSummary(dataObj) {
    return HTTP.post(getRewardSummaryUrl, dataObj);
  },
  getRewardsRankBoard(dataObj) {
    let obj = {limit:10}
    return HTTP.post(getRewardsRankBoardUrl,obj);
  },
  addRewards(dataObj) {
    // let name=dataObj.name;
    // let points=dataObj.points;
    return HTTP.post(addRewards, dataObj);
    // return HTTP.post(addRewards,{
    //   name,
    //   points
    // },null);
  },
  updateRewards(dataObj) {
    // let name=dataObj.name;
    // let points=dataObj.points;
    // let id=dataObj.id;
    return HTTP.put(updateRewards, dataObj);
    // return HTTP.put(updateRewards,{
    //   id,
    //   name,
    //   points
    // },null);
  },
  deleteRewards(id) {
    return HTTP.delete(deleteRewards + id);
  },
  deleteUserRewards(user_id, rewards_id) {
    return HTTP.delete(deleteUserRewards + `${user_id}/${rewards_id}`);
  },
  getUserFilters(page) {
    return HTTP.post(userFilters, null, {
      params: {
        "page": page
      }
    });
  },

  credit_month(month) {
    return HTTP.post(update_credit_month + month)
  },

  leave_debit_month(month) {
    return HTTP.post(update_leave_debit_month + month)
  },

  LWP_month(month) {
    return HTTP.post(update_LWP_month, month)
  },

  getskillsDropdown() {
    return HTTP.get(skillsurl)
  },

  // getusersSkills() {
  //   return HTTP.get(usersskillsurl)
  // },

  addskill(data, id) {
    let array = JSON.stringify(data);
    // console.log(array);
    return HTTP.post(addskillsurl + id, array, null)
    // return HTTP.get(addskillsurl)
  },

  getskillbyuser(id) {
    return HTTP.get(getskillbyuser + id)
  },

  // deleteskill(id){
  //   return HTTP.delete(deleteskill + id)
  // },

  getKRAbyid(id) {
    return HTTP.get(getkrabyid + id)
  },

  getKRADropdown() {
    return HTTP.get(kraurl)
  },

  // getusersKRA() {
  //   return HTTP.get(userskraurl)
  // },

  addKRA(data, id) {
    let array = JSON.stringify(data);
    return HTTP.post(addkraurl + id, array, null)
  },

  getVersion() {
    return HTTP.get(versionUrl)
  },

  updateKRA(uid, id, data) {
    let name = data.name == '' ? null : data.name;
    let description = data.description;
    return HTTP.put(updatekraurl + id + "/" + uid, { name, description }, null)
  },

  getkrabyuser(id) {
    return HTTP.get(getkrabyuser)
  },

  deleteKRA(id) {
    return HTTP.delete(deletekra + id)
  },

  getteammatesreportserverParams(serverParams, signal) {
    // return HTTP.post(teammatesreporturl,serverParams,signal)
    return HTTP({
      method: 'POST',
      url: teammatesreporturl,
      data: serverParams,
      signal: signal
    });
  },

  getdailydiscrepancy(serverParams) {
    return HTTP.post(dailydiscrepancyurl, serverParams, null)
  },

  getUser(userId) {
    return HTTP.get(getUser.concat(userId));
  },
  CheckUsername(dataObject) {
    var username = dataObject.username;
    return HTTP.post(checkusername, {
      username,
    })
  },

  addnewpassword(dataObject) {
    var password = dataObject.password;
    var token = dataObject.time_stamp;
    return HTTP.post(resetpasswordurl, {
      password,
      token,
    })
  },

  GetAllValidUsersList() {
    return HTTP.post(getallvaliduserslist)
  },
  // addnewpassword(dataObject) {
  //   // fastapi jwt auth requires the data to be submitted as multipart/form-data
  //   var data = new FormData();
  //   data.append('username', dataObject.username);
  //   data.append('password', dataObject.password);
  //   data.append('time_stamp', dataObject.time_stamp);
  //   return HTTP.post(updateuserpasswordurl, data, {
  //       headers: {
  //           'Content-type': 'multipart/form-data',
  //       }
  //   })
  // },

  loginUser(dataObject) {
    // fastapi jwt auth requires the data to be submitted as multipart/form-data
    var data = new FormData();
    data.append('username', dataObject.username);
    data.append('password', dataObject.password);
    return HTTP.post(loginURL, data, {
      headers: {
        'Content-type': 'multipart/form-data',
      }
    })
  },
  getGoalsByUser(id) {
    return HTTP.get(goalbyuser + id);
  },
  getAliasByClient(id) {
    return HTTP.get(aliasbyclient + id);
  },
  GetAssignedGoals(dataObject) {
    return HTTP.post(assignedgoalslist, dataObject, null)
  },
  GetTeamMatesGoals(dataObject) {
    return HTTP.post(teammatesgoals, dataObject, null)
  },
  getGoalById(id) {
    return HTTP.get(getgoalbyid + id)
  },
  CreateUserGoal(serverParams) {
    return HTTP.post(createusergoal, serverParams, null)
  },
  UpdateGoalDetails(dataObject) {
    return HTTP.put(updategoaldetails, dataObject, null)
  },
  UpdateAliasByClient(id) {
    return HTTP.put(updatealiasbyclient, id, null)
  },

  getPreviousGoal() {
    return HTTP.get(previousgoalurl)
  },
  CheckUserPerDayMinutes() {
    return HTTP.get(checkperdayminutes);
  },
  updatePreviousgoal(dataObject) {
    // console.log(dataObject);
    // fastapi jwt auth requires the data to be submitted as multipart/form-data
    var previousgoal = dataObject.previousgoal;
    var reasonof_previousgoal = dataObject.reasonof_previousgoal;
    var goal_percent = dataObject.goal_percent;
    return HTTP.post(previousgoalurl, null, {
      params: {
        previousgoal,
        reasonof_previousgoal,
        goal_percent,
      }
    })
  },

  getTodaysgoal() {
    return HTTP.get(todaysgoalurl)
  },
  updateTodaysgoal(dataObject) {
    // console.log(dataObject);
    // fastapi jwt auth requires the data to be submitted as multipart/form-data
    var todaysgoal = dataObject.todaysgoal;
    return HTTP.post(todaysgoalurl, null, {
      params: {
        todaysgoal
      }
    })
  },

  getAllSubclinetProjects() {
    return HTTP.get(groupprojectsurl)
  },

  getAllProjects() {
    return HTTP.get(projectsurl)
  },
  getAllProjectscolumnfilter(serverParams) {
    // console.log("hellofilters",serverParams);
    return HTTP.post(projectfilter, serverParams, null)
  },
  getProjectbyid(id) {
    return HTTP.get(projectidurl + id)
  },
  createProject(dataObject) {
    return HTTP.post(createprojectsurl, dataObject, null)
  },
  AssignProjectToUser(dataObject) {
    return HTTP.post(assignprojecttouserurl, {
      project_id: dataObject.project_id,
      users: dataObject.users,
    })
  },
  UpdateUsersAlias(dataObject) {
    return HTTP.post(updateassignedusersalias, dataObject, null)
  },
  getclientDropdown() {
    return HTTP.get(clientlisturl)
  },
  getmanageridDropdown() {
    return HTTP.get(activeuserurl)
  },
  getprojectandreportmeDropdown() {
    return HTTP.get(projectandreportmeusersurl)
  },
  getMilestonebyid(id) {
    return HTTP.get(getmilestonesidurl + id)
  },
  delete_project(id) {
    return HTTP.delete(deleteprojectsurl + id)
  },
  getMilestonedate(id) {
    return HTTP.get(getmilestonedateurl + id)
  },
  getUserDesignationDropdown() {
    return HTTP.get(userDesignationDropdownUrl);
  },
  getUserTypeDropdown() {
    return HTTP.get(userTypeDropdownUrl);
  },
  get_all_deleted_users() {
    return HTTP.post(deleteduserslisturl);
  },
  make_user_active(id) {
    return HTTP.post(makeuseractiveurl + id)
  },
  updateProject(id, dataObject) {
    // console.log("id is",id);
    // console.log("data is",dataObject);
    // var sub_client = dataObject.sub_client;
    // var name = dataObject.name;
    // var start_date = dataObject.start_date;
    // var description = dataObject.description;
    // var end_date = dataObject.end_date;
    // var project_type = dataObject.project_type;
    // var managerid = dataObject.managerid;
    // var project_status_id = dataObject.statusId;
    // var repo_url = dataObject.repo_url;
    // var access_token = dataObject.access_token;
    // var image=dataObject.img_obj_id;
    return HTTP.put(updateprojectsurls + id, dataObject, null, {
      // params: {...dataObject}
    })
  },
  getAllMilestones() {
    return HTTP.post(milestonesurl)
  },
  search_milestones(serverParams) {
    // console.log("hellofilters", serverParams);
    return HTTP.post(searchmilestonesurl, serverParams, null)
  },

  createMilestones(dataObject) {
    return HTTP.post(createmilestonesurl, {
      name: dataObject.name,
      estimated_start_time: dataObject.estimated_start_time,
      estimated_end_time: dataObject.estimated_end_time,
      actual_start_time: dataObject.actual_start_time,
      actual_end_time: dataObject.actual_end_time,
      details: dataObject.details,
      project_id: dataObject.project_id,
    })
  },
  getprojectDropdownForTask() {
    return HTTP.get(projecttaskurl)
  },
  getTaskCategoryDropdown() {
    return HTTP.get(getTaskCategoryUrl);
  },
  delete_milestone(id) {
    return HTTP.delete(deletemilestoneurl + id)
  },
  updateMilestone(id, dataObject) {
    // console.log(dataObject);
    // var project_id = dataObject.project_id;
    // var name = dataObject.name;
    // var estimated_start_time = dataObject.estimated_start_time;
    // var estimated_end_time = dataObject.estimated_end_time;
    // var actual_start_time = dataObject.actual_start_time;
    // var actual_end_time = dataObject.actual_end_time;
    // var details = dataObject.details;
    return HTTP.put(updatemilestoneurl + id, dataObject, null, {
      // params: {
      //   project_id,
      //   name,
      //   estimated_start_time,
      //   estimated_end_time,
      //   actual_start_time,
      //   actual_end_time,
      //   details,
      // }
    })
  },
  getAllProjectMilestones(id) {
    return HTTP.get(projectmilestoneurl + id)
  },

  AssignTaskToUser(dataObject) {
    return HTTP.post(assigntasktouserurl, {
      task_id: dataObject.task_id,
      users: dataObject.users,
    })
  },
  getProjectAssignUsers(id) {
    return HTTP.get(getprojectassignurl + id)
  },
  getProjectUnAssignUsers(id) {
    return HTTP.get(getprojectunassignuser + id)
  },
  getTaskUnAssignUsers(id) {
    return HTTP.get(gettaskunassignuser + id)
  },
  getAllAssignedProjectUsers(id) {
    return HTTP.get(getpreassignedtoprojectusers + id)
  },
  get_per_day_minutes_of_users(dataObject) {
    return HTTP.post(getdailyhoursofusers, dataObject, null)
  },
  search_your_task(serverParams) {
    // console.log("taskserverparams",serverParams);
    return HTTP.post(searchyourtaskurl, serverParams, null)
  },
  search_team_mates_task(serverParams) {
    // console.log("taskserverparams",serverParams);
    return HTTP.post(searchteammatestaskurl, serverParams, null)
  },
  getTaskbyid(id) {
    return HTTP.get(gettaskidurl + id)
  },
  GetAllTasks(serverParams) {
    // console.log(serverParams);
    return HTTP.post(getalltasks, serverParams, null)
  },
  UpdateTaskDetails(serverParams) {
    if (serverParams.action == "update") {
      let dataObject = {
        milestone_id: serverParams.milestone_id,
        short_desc: serverParams.short_desc,
        description: serverParams.description,
        start_date: serverParams.start_date,
        due_date: serverParams.due_date,
        estimated_time: serverParams.estimated_time,
        priority: serverParams.priorityId,
        completed: serverParams.completed,
        users: serverParams.users,
        task_status_id: serverParams.statusId,
        task_categories: serverParams.task_categories,
        task_id: serverParams.task_id,
        action: serverParams.action
      }
      serverParams = dataObject;
    }
    return HTTP.put(updatetaskdetails, serverParams, null)
  },
  createTask(dataObject) {
    return HTTP.post(createtaskurl, {
      milestone_id: dataObject.milestone_id,
      short_desc: dataObject.short_desc,
      description: dataObject.description,
      start_date: dataObject.start_date,
      due_date: dataObject.due_date,
      estimated_time: dataObject.estimated_time,
      priority: dataObject.priorityId,
      task_status_id: dataObject.statusId,
      users: dataObject.users,
      files: dataObject.files,
      task_categories: dataObject.task_categories
    })
  },
  updateTask(id, dataObject) {
    return HTTP.put(updatetaskurl + id, {
      milestone_id: dataObject.milestone_id,
      short_desc: dataObject.short_desc,
      description: dataObject.description,
      start_date: dataObject.start_date,
      due_date: dataObject.due_date,
      estimated_time: dataObject.estimated_time,
      priority: dataObject.priorityId,
      completed: dataObject.completed,
      users: dataObject.users,
      task_status_id: dataObject.statusId,
      task_categories: dataObject.task_categories,
      files: dataObject.files
    })
  },
  delete_task(id) {
    return HTTP.delete(deletetaskurl + id)
  },
  getallyourreports() {
    return HTTP.get(getallyourreportsurl)
  },
  getallteammatesreports() {
    return HTTP.get(getallteammatesreportlisturl)
  },
  search_your_report(serverParams) {
    return HTTP.post(searchyourtaskreporturl, serverParams, null)
  },
  search_team_mates_report(serverParams) {
    return HTTP.post(searchteammatestaskreporturl, serverParams, null)
  },
  getreportsbyid(id) {
    return HTTP.get(gettaskreportbyidurl + id)
  },
  CreateReport(dataObject) {
    return HTTP.post(createprojecttaskreport, {
      task: dataObject.task,
      description: dataObject.description,
      date: dataObject.date,
      time: dataObject.time,
      task_categories: dataObject.task_categories,
      files: dataObject.files,
      commit_number: dataObject.commit_number,
      commit_link: dataObject.commit_link,
      appv_time: dataObject.appv_time,
      action: dataObject.action,
      confirm: dataObject.confirm
    })
  },
  UpdateReport(dataObject) {
    return HTTP.put(updateprojecttaskreport, {
      report_id: dataObject.report_id,
      description: dataObject.description,
      date: dataObject.date,
      time: dataObject.time,
      task_categories: dataObject.task_categories,
      files: dataObject.files,
      commit_number: dataObject.commit_number,
      commit_link: dataObject.commit_link,
      appv_time: dataObject.appv_time,
      action: dataObject.action,
      confirm: dataObject.confirm
    })
  },
  UpdateApprovedReportDetails(dataObject) {
    return HTTP.put(updateapprovedreportdetails, dataObject, null)
  },
  delete_report(id) {
    return HTTP.delete(deleteprojecttaskreporturl + id)
  },
  ApproveReport(data) {
    return HTTP.post(approvereporturl, data, { timeout: process.env.VUE_APP_REQUEST_TIMEOUT * 1000 })
    // process.env.VUE_APP_REQUEST_TIMEOUT*1000
    // return HTTP({
    //   method:'POST',
    //   url:approvereporturl,
    //   data:{ time_and_report_id:data },
    //   signal:signal
    // })
  },

  getSnapshot(searched_date) {
    return HTTP.post(snapshotURL, searched_date, null)
  },

  getApprovedHoursReport(reqObj) {
    return HTTP.post(approvedHoursReportUrl, reqObj, null);
  },

  getProductivityReport(reqObj) {
    return HTTP.post(productivityReportUrl, reqObj, null);
  },

  exportProductivityReport(reqObj) {
    return HTTP.post(exportProductivityReportUrl, reqObj, {responseType:'blob'});
  },

  exportApprovedHoursReport(reqObj) {
    return HTTP.post(exportApprovedHoursReportUrl, reqObj, {responseType:'blob'});
  },

  GetReportedHoursByDate(user_id, date) {
    return HTTP.post(getreportedhoursbydate, {
      reports_date: date,
      user_id: user_id
    })
  },
  GetAllTeamLeadDropdowns() {
    return HTTP.get(getteamleaddropdown)
  },

  GetAllUserAllocationReport() {
    return HTTP.get(userallocationreportlisturl)
  },

  UserAllocationFilterList(serverParams) {
    return HTTP.post(userallocationreportfilterlisturl, serverParams, null)
  },

  DisplayAlluserAllocationReportPages(pageNum, pageSize) {
    return HTTP.get(userallocationreportpagesurl, {
      params: {
        page_num: pageNum,
        page_size: pageSize
      }
    })
  },

  createClient(dataObject) {
    // console.log("createClient",dataObject)
    return HTTP.post(createclienturl, dataObject, {

    })
  },
  getAllClient() {
    return HTTP.get(clientlisturl)
  },
  search_client(serverParams) {
    return HTTP.post(searchclienturl, serverParams, null)
  },
  search_subclient(serverParams) {
    return HTTP.post(searchsubclienturl, serverParams, null)
  },
  updateClient(id, dataObject) {
    // console.log(dataObject);
    // var name = dataObject.name;
    // var manager_id = dataObject.manager_name;
    // var slug = dataObject.slug;
    return HTTP.put(clientupdateurl + id, dataObject, null, {
      // params: {
      //   name,
      //   manager_id,
      //   slug,
      // }
    })
  },
  getClientbyid(id) {
    return HTTP.get(clientlistbyidurl + id)
  },
  search_user(serverParams) {
    // console.log("userfilter", serverParams);
    return HTTP.post(searchuserurl, serverParams, null)
  },
  deleteClient(id) {
    return HTTP.delete(deleteclienturl + id)
  },


  getClientUnAssignUsers(id) {
    return HTTP.get(getclientunassignusers + id)
  },

  SetAssignedUsersToClient(dataObject) {
    return HTTP.post(setassigneduserstoclient, dataObject, null)
  },

  createSubClient(dataObject) {
    return HTTP.post(createsubclienturl, {
      client_id: dataObject.client_id,
      subclientname: dataObject.subclientname,
      contactPerson: dataObject.contactPerson,
      contactEmail: dataObject.contactEmail,
      manager_user_id: dataObject.manager_user_id,
    })
  },
  getAllSubClient() {
    return HTTP.get(subclientlisturl)
  },
  updateSubClient(id, dataObject) {
    // console.log(dataObject);
    // var client_id = dataObject.client_id
    // var subclientname = dataObject.subclientname
    // var contactPerson = dataObject.contactPerson
    // var contactEmail = dataObject.contactEmail
    // var manager_user_id = dataObject.manager_user_id
    return HTTP.put(subclientupdateurl + id, dataObject, null, {
      // params: {
      //   client_id,
      //   subclientname,
      //   contactPerson,
      //   contactEmail,
      //   manager_user_id,
      // }
    })
  },
  getSubClientbyid(id) {
    return HTTP.get(subclientlistbyidurl + id)
  },
  deleteSubClient(id) {
    return HTTP.delete(deletesubclienturl + id)
  },
  checkAndResetPassword(id, new_password, old_password) {
    return HTTP.post(updatePasswordurl, {
      new_password: new_password,
      old_password: old_password
    })
  },
  getListOrganization() {
    return HTTP.get(getlistorganizationurl)
  },

  updatetheuser(id, dataObject) {
    // var first_name = dataObject.first_name;
    // var middle_name = dataObject.middle_name;
    // var last_name = dataObject.last_name;
    // var pan = dataObject.pan;
    // var uan_no = dataObject.uan_no;
    // var dob = dataObject.dob;
    // var doj = dataObject.doj;
    // var doe = dataObject.doe;
    // var user_type=dataObject.user_type;
    // var emp_code = dataObject.emp_code;
    // var o_email = dataObject.o_email;
    // var username = dataObject.username;
    // var blood_group = dataObject.blood_group;
    // var medical_info = dataObject.medical_info;
    // var p_mobile = dataObject.p_mobile;
    // var p_email = dataObject.p_email;
    // var p_add1 = dataObject.p_add1;
    // var p_city = dataObject.p_city;
    // var p_state = dataObject.p_state;
    // var p_country = dataObject.p_country;
    // var p_zip = dataObject.p_zip;
    // var c_add1 = dataObject.c_add1;
    // var c_city = dataObject.c_city;
    // var c_state = dataObject.c_state;
    // var c_country = dataObject.c_country;
    // var c_zip = dataObject.c_zip;
    // var report_to = dataObject.report_to;
    // var login_slot = dataObject.login_slot;
    // var user_role = dataObject.user_role;
    // var designation_id = dataObject.user_designation;
    // var per_day_minutes=dataObject.per_day_minutes;
    // var weekend_strategy=dataObject.weekend_strategy;
    // var image=dataObject.img_obj_id;
    // var organization=dataObject.organization;
    // var reporting=dataObject.reporting;
    // var bank_name=dataObject.bank_name;
    // var bank_account_no = dataObject.bank_account_no;
    // var esic_no = dataObject.esic_no;
    // var ifsc_no = dataObject.ifsc_no;
    // var gender = dataObject.gender;
    // var emergency_contact_number = dataObject.emergency_contact_number;
    // var emergency_contact_name = dataObject.emergency_contact_name;
    // var emergency_contact_relation = dataObject.emergency_contact_relation;
    return HTTP.put(updateuserurl + id, dataObject, null, {
      // params: {
      //   first_name ,//: dataObject.first_name,
      //   middle_name ,//: dataObject.middle_name,
      //   last_name ,//: dataObject.last_name,
      //   pan ,//: dataObject.pan,
      //   uan_no,
      //   dob ,//: dataObject.dob,
      //   doj ,//: dataObject.doj,
      //   doe ,//: dataObject.doe,
      //   image,
      //   emp_code ,//: dataObject.emp_code,
      //   o_email ,//: dataObject.o_email,
      //   username ,//: dataObject.username,
      //   blood_group ,//: dataObject.blood_group,
      //   medical_info ,//: dataObject.medical_info,
      //   p_mobile ,//: dataObject.p_mobile,
      //   p_email ,//: dataObject.p_email,
      //   p_add1 ,//: dataObject.p_add1,
      //   p_city ,//: dataObject.p_city,
      //   p_state ,//: dataObject.p_state,
      //   p_country ,//: dataObject.p_country,
      //   p_zip ,//: dataObject.p_zip,
      //   c_add1 ,//: dataObject.c_add1,
      //   c_city ,//: dataObject.c_city,
      //   c_state ,//: dataObject.c_state,
      //   c_country ,//: dataObject.c_country,
      //   c_zip ,//: dataObject.c_zip,
      //   report_to ,//: dataObject.report_to,
      //   login_slot ,//: dataObject.login_slot,
      //   user_role ,//: dataObject.user_role
      //   designation_id,
      //   per_day_minutes,
      //   weekend_strategy,
      //   user_type,
      //   organization,
      //   reporting,
      //   bank_name,
      //   bank_account_no,
      //   esic_no,
      //   ifsc_no,
      //   gender,
      //   emergency_contact_number,
      //   emergency_contact_name,
      //   emergency_contact_relation,
      // }
    })
  },
  getUsers() {
    return HTTP.get(displayallusersurl)
  },
  displayAllUserPages(pageNum, pageSize) {
    return HTTP.get(displayalluserpagesurl, {
      params: {
        page_num: pageNum,
        page_size: pageSize
      }
    })
  },
  DeletedUsersFilterlist(serverParams) {
    return HTTP.post(deletedusersfilterlist, serverParams, null)
  },

  // displayAllMilestonePages(pageNum, pageSize) {
  //   return HTTP.get(displayallmilestonepagesurl, {
  //     params: {
  //       page_num: pageNum,
  //       page_size: pageSize
  //     }
  //   })
  // },
  getCategoryDropdown() {
    return HTTP.get(categoriesDropdownUrl);
  },
  displayYoursReportPages(pageNum, pageSize) {
    return HTTP.get(displayyoursreportpagesurl, {
      params: {
        page_num: pageNum,
        page_size: pageSize
      }
    })
  },
  displayTeamMatesReportPages(pageNum, pageSize) {
    return HTTP.get(displayteammatesreportpages, {
      params: {
        page_num: pageNum,
        page_size: pageSize
      }
    })
  },
  // displayAllLeavesPages(pageNum, pageSize) {
  //   console.log("This request is made")
  //   return HTTP.get(displayAllLeavesPagesUrl, {
  //     params: {
  //       page_num: pageNum,
  //       page_size: pageSize
  //     }
  //   })
  // },
  displayAllLeavesPages(pageNum, pageSize, table) {
    return HTTP.get(displayAllLeavesPagesUrl, {
      params: {
        page_num: pageNum,
        page_size: pageSize,
        table: table
      }
    })
  },
  getUserById(id) {
    return HTTP.get(getuserbyidurl + id)
  },
  getUserProfile(id) {
    return HTTP.get(getuserprofileurl + id)
  },
  getUserInfo(id) {
    return HTTP.get(getuserinfourl + id)
  },
  delete_user(id) {
    return HTTP.put(deleteuserurl + id)
  },
  newUserAdd(dataObject) {
    // var first_name = dataObject.first_name;
    // var middle_name = dataObject.middle_name;
    // var last_name = dataObject.last_name;
    // var pan = dataObject.pan;
    // var uan_no = dataObject.uan_no;
    // var dob = dataObject.dob;
    // var doj = dataObject.doj;
    // var doe = dataObject.doe;
    // var emp_code = dataObject.emp_code;
    // var o_email = dataObject.o_email;
    // var username = dataObject.username;
    // var password = dataObject.password;
    // var blood_group = dataObject.blood_group;
    // var medical_info = dataObject.medical_info;
    // var p_mobile = dataObject.p_mobile;
    // var p_email = dataObject.p_email;
    // var p_add1 = dataObject.p_add1;
    // var p_city = dataObject.p_city;
    // var p_state = dataObject.p_state;
    // var p_country = dataObject.p_country;
    // var p_zip = dataObject.p_zip;
    // var c_add1 = dataObject.c_add1;
    // var c_city = dataObject.c_city;
    // var c_state = dataObject.c_state;
    // var c_country = dataObject.c_country;
    // var c_zip = dataObject.c_zip;
    // var report_to = dataObject.report_to;
    // var login_slot = dataObject.login_slot;
    // var user_role = dataObject.user_role;
    // var designation_id = dataObject.user_designation;
    // var per_day_minutes=dataObject.per_day_minutes;
    // var weekend_strategy=dataObject.weekend_strategy;
    // var user_type=dataObject.user_type;
    // var bank_name=dataObject.bank_name;
    // var bank_account_no = dataObject.bank_account_no;
    // var esic_no = dataObject.esic_no;
    // var ifsc_no = dataObject.ifsc_no;
    // var gender = dataObject.gender;
    // var emergency_contact_number = dataObject.emergency_contact_number;
    // var emergency_contact_name = dataObject.emergency_contact_name;
    // var emergency_contact_relation = dataObject.emergency_contact_relation;
    // var organization = dataObject.organization
    // var img=dataObject.img_obj_id
    // var reporting=dataObject.reporting
    return HTTP.post(createUserUrl, dataObject, null, {
      // params: {
      //   first_name,
      //   middle_name,
      //   last_name,
      //   pan,
      //   uan_no,
      //   dob,
      //   doj,
      //   doe,
      //   emp_code,
      //   o_email,
      //   username,
      //   password,
      //   blood_group,
      //   medical_info,
      //   p_mobile,
      //   p_email,
      //   p_add1,
      //   p_city,
      //   p_state,
      //   p_country,
      //   p_zip,
      //   c_add1,
      //   c_city,
      //   c_state,
      //   c_country,
      //   c_zip,
      //   report_to,
      //   login_slot,
      //   user_role,
      //   designation_id,
      //   per_day_minutes,
      //   weekend_strategy,
      //   user_type,
      //   bank_name,
      //   bank_account_no,
      //   esic_no,
      //   ifsc_no,
      //   gender,
      //   img,
      //   reporting,
      //   organization,
      //   emergency_contact_number,
      //   emergency_contact_name,
      //   emergency_contact_relation,
      // }
    })
  },

  getUserRoleDropdown() {
    return HTTP.get(roleuserurl, +'id')
  },

  getUserPermissionDropdown() {
    return HTTP.get(permissionurl, +'id')
  },

  updateUser(dataObject) {
    var flag = dataObject.flag;
    var break_time = dataObject.breakTime;
    var end_time = dataObject.end_time;
    //pass values as query param
    return HTTP.post(updateURL, null, {
      params: {
        flag,
        break_time,
        end_time,
      }
    })
  },

  UpdateApprovedLeaves(dataObject) {
    return HTTP.put(udpateapprovedleaves, dataObject, null)
  },

  getCurrentUser() {
    return HTTP.get(getCurrentUserURL);
  },

  
  //get_dashboard_summary
  getDashboardSummary(){
    return HTTP.get(get_dashboard_summary)
  },


  getUserLogs(userId) {
    return HTTP.get(users, {
      params: {
        user_id: userId
      }
    })
  },
  get_all_users() {
    return HTTP.get(userlisturl);
  },
  get_countries() {
    return HTTP.get(countriesList)
  },
  get_statesList(countryID) {
    return HTTP.get(statesList + countryID)
  },
  get_citiesList(stateID) {
    return HTTP.get(citiesList + stateID)
  },

  getAttendanceReport(dataObject) {
    return HTTP.post(attendanceReportURL, dataObject, null)
  },

  getAttendanceByDate() {
    return HTTP.get(attendancebytoday)
  },
  getLiveTimeSpent() {
    return HTTP.get(getlivetimespent)
  },
  getUserReportsByDate(serverParams) {
    return HTTP.post(getreportsbydateURL, serverParams, null)
  },

  getMonthlyAttendanceReport(searched_date) {
    return HTTP.get(monthlyattendanceReportURL + searched_date)
  },

  getAttendanceLogByDate(serverParams) {
    return HTTP.post(getattendancelogbydate, serverParams, null)
  },
  CreateAttendanceUpdateRequest(dataObject) {
    return HTTP.post(createrequestforupdateattendance, {
      user_log_id: dataObject.user_log_id,
      date: dataObject.date_of_update_attendance,
      start_time: dataObject.start_time,
      end_time: dataObject.end_time,
      user_id: dataObject.user_id,
      choise: dataObject.choise
    })
  },
  get_update_attendance_request(serverParams) {
    return HTTP.post(getallupdateattendancerequest, serverParams, null)
  },
  UpdateAttendance(dataObject) {
    return HTTP.put(updateattendanceurl, dataObject, null)
  },
  createAsset(dataObject) {
    // console.log(dataObject);
    var name = dataObject.name;
    var description = dataObject.description;
    var price = dataObject.price;
    var item_id = dataObject.item_id;
    var user_id = dataObject.user_id;
    return HTTP.post(createassetsurl, null, {
      params: {
        name,
        description,
        price,
        item_id,
        user_id,
      }
    })
  },

  // getAsset() {
  //   return HTTP.get(assetsurl);
  // },

  updateAsset(id, dataObject) {
    var name = dataObject.name;
    var description = dataObject.description;
    var price = dataObject.price;
    var item_id = dataObject.item_id;
    var user_id = dataObject.user_id;
    return HTTP.put(updateasseturl + id, null, {
      params: {
        name,
        description,
        price,
        item_id,
        user_id,
      }
    })
  },

  delete_asset(id) {
    return HTTP.delete(deleteasseturl + id)
  },

  getAllAssetscolumnfilter(serverParams) {
    return HTTP.post(assetfilter, serverParams, null)
  },

  // Items
  getItems() {
    return HTTP.get(itemurl);
  },

  createItem(dataObject) {
    var name = dataObject.name;
    return HTTP.post(createitemurl, null, {
      params: {
        name,
      }
    })
  },

  getAssetById(id) {
    return HTTP.get(getassetbyidurl + id)
  },

  get_project_user() {
    return HTTP.get(getprojectuser)
  },

  getUserConfiguration() {
    return HTTP.get(getuserconfigurationsurl)
  },
  updateUserConfiguration(dataObject) {
    // console.log(dataObject);
    var login_logout_reminder = dataObject.login_logout_reminder;
    var reports = dataObject.reports;
    return HTTP.put(updateuserconfigurationurl, null, {
      params: {
        login_logout_reminder,
        reports,
      }
    })
  },

  getLeaveType() {
    return HTTP.get(getleavetype)
  },

  getLeaveReport(searched_date) {
    // return HTTP.get(leaveReportURL + searched_date)
    return HTTP.post(leaveReportURL, searched_date, null)

  },

  createLeaves(dataObject) {
    return HTTP.post(createleavesurl, dataObject, null)
  },

  createCreditDebitLeaves(data) {
    // console.log("----- data url ",dataObject,leave_type, user_id, credit_debit_type)
    return HTTP.post(createcreditdebitleaveurl, data, null)
  },

  updateLeaves(id, dataObject) {
    // console.log("--update userjs ",dataObject);
    return HTTP.put(updateleavesurl + id, dataObject, null)
  },

  getLeaves() {
    return HTTP.get(getleavesurl);
  },

  getLeaveById(id) {
    return HTTP.get(getleavesbyidurl + id)
  },

  approve_leaves(id) {
    return HTTP.put(approveleavesurl + id);
  },

  reject_leave(id) {
    return HTTP.put(rejectleavesurl + id);
  },

  delete_leave(id) {
    return HTTP.delete(deleteleavesurl + id)
  },

  getYourLeaveList(serverParams) {
    return HTTP.post(yourleavelist, serverParams, null)
  },
  getTeamMatesLeaveList(serverParams) {
    return HTTP.post(teammatesleavelist, serverParams, null)
  },
  getUserLeaveTypes(month, leave_type, id) {
    return HTTP.post(`${user_leave_types}${month}/${leave_type}/${id}`);
  },
  getAllPriorities() {
    return HTTP.get(getallpriorities)
  },
  getAllProjectStatus() {
    return HTTP.get(getallprojectstatus)
  },
  getAllTasksStatus() {
    return HTTP.get(getalltaskstatus)
  },
  getStatusById(id) {
    return HTTP.get(getstatusbyid + id)
  },
  getPriorityById(id) {
    return HTTP.get(getprioritybyid + id)
  },


  Quote_User_Status(dataObject) {
    return HTTP.post(makequoteseenurl, {
      user_id: dataObject.user_id,
      quote_id: dataObject.quote_id,
    })
  },
  CreateQuote(dataObject) {
    return HTTP.post(createquoteurl, dataObject)
  },
  getTodaysQuotes() {
    return HTTP.get(displaytodayquotesurl)
  },

  getQuotesById(id) {
    return HTTP.get(getquotesbyid + id)
  },
  // getAllQuotes(){
  //   return HTTP.get(getallquotesurl)
  // },
  // getAllQuotesPages(pageNum, pageSize) {
  //   return HTTP.get(getallquotespages, {
  //     params: {
  //       page_num: pageNum,
  //       page_size: pageSize
  //     }
  //   })
  // },
  updateQuotes(id, dataObject) {
    var image = dataObject.img_obj_id;
    var text = dataObject.text;
    var date = dataObject.display_date;
    return HTTP.put(updatequotesurl + id, null, {
      params: {
        image,
        text,
        date,
      }
    })
  },
  delete_quote(id) {
    return HTTP.delete(deletequotesbyidurl + id)
  },
  search_quotes(serverParams) {
    return HTTP.post(quotesfilterlist, serverParams, null)
  },
  getPreAssighedUrl(image_type) {
    var image_type = "?image_type=" + image_type
    return HTTP.get(getpreassignedurl + image_type)
  },

  CreateNotification(dataObject) {
    return HTTP.post(createnotificationurl, {
      text: dataObject.notification_text,
    })
  },

  getAllNotification(serverParams) {
    return HTTP.post(getallnotificationsurl, serverParams, null)
  },

  delete_notification(id) {
    return HTTP.delete(deletenotificationbyidurl + id)
  },

  addRole(dataObject) {
    // console.log("===========",a,b);
    return HTTP.post(addroleurl, {
      name: dataObject.name,
      description: dataObject.description,
      permissions_id: dataObject.permissions_id
    })
  },
  deleteRole(id) {
    return HTTP.delete(deleteroleurl + id);
  },

  getTimeSpentReport(searched_date) {
    return HTTP.get(TimeSpentReportURL + searched_date)
  },

  getHierarchyOfUser() {
    return HTTP.get(gethierarchyOfUserurl)
  }
}
